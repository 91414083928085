import { blackListActions } from 'store/constants/blacklist';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

export const blackListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case blackListActions.GET_BLACKLIST: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};
